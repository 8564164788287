<template>
<div>
<div class="popup open" v-if="modalOpen">
    <div class="popup-area">
      <a class="popup-close" href="#"  @click="openModal(false)"></a>
      <h2 class="poptitle margin02">新增需量反應執行紀錄</h2>
      <div class="form-line">
        <div class="form-item">
          <span>得標價格</span>
          <input type="number" class="w-m" v-model="winBid">
          <span>元，</span>
        </div>
        <div class="form-item">
          <span>抑低契約容量</span>
          <input type="text" class="w-m" v-model="reduceCap">
          <span>kW</span>
        </div>
      </div>
      <div class="form-line">
        <div class="form-item">
          <span>執行期間</span>
          <div class="inputbtn w-l" style='margin:0px 10px 0px 5px'>
            <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
          </div>
        </div>
        <div class="form-item">
          <select class="w-s" v-model="cblSelectBeginHour">
            <option v-for = "i in 24" :key = i :value="('0' + i).slice(-2)">{{i}}</option>
          </select>
          <select class="w-s" v-model="cblSelectMin">
            <option>00</option>
            <option>15</option>
            <option>30</option>
            <option>45</option>
          </select>
          <span>&nbsp;~&nbsp;</span>
          <span class="font-size-xxl weight-n color-blue">{{parseInt(cblSelectBeginHour) + 2}}:</span>
          <span class="font-size-xxl weight-n color-blue">{{cblSelectMin}}</span>
        </div>
          <div class="form-item">
            <span class="font-size-xxl weight-n" style="margin:5px">共 </span>
            <span class="font-size-xxl weight-n color-blue">2</span>
            <span class="color-blue">小時</span>
          </div>
      </div>
      <div class="form-line margin02">
        <div class="form-item">
          <span>實際總抑低容量</span>
          <input type="text" class="w-l" v-model="realReduceCap" @change="setInnerPieChart">
          <span>kW，</span>
        </div>
        <div class="form-item">
          <span>執行率 </span>
          <span class="font-size-xxl weight-n color-blue">{{dbDataHandle(realReduceCap/reduceCap * 100)}}</span>
          <span class="color-blue">%</span>
        </div>
      </div>
      <!-- table -->
      <div class="tableframe margin02">
        <table class="padding-small box-border">
          <tr class="tr-light border-bottom">
            <th>單位</th>
            <th>預估抑低容量(kW)</th>
            <th>實際抑低容量(kW)</th>
            <th>貢獻度(%)</th>
            <th>備註</th>
          </tr>
          <tr v-for="(i, idx) in emsDetail" :key="idx">
            <td>{{i.ems}}</td>
            <td>{{i.predictReduceCap}}</td>
            <td><input type="number" class="w-l" :value="i.realReduceCap"  @input="i.realReduceCap = Number($event.target.value)" @change="emsDetailcontribution()"></td>
            <td>{{i.contribution}}</td>
            <td><input type="text" class="w-l" :value="i.remark" @input="i.remark = $event.target.value"></td>
          </tr>
          <tr class="tr-dark font-size-l">
            <td>合計</td>
            <td>{{reduceCap}} kW</td>
            <!-- <td>{{realReduceCap}} kW</td> -->
            <td style="color: red" v-if="emsDetailRealReduceCap() != realReduceCap">{{emsDetailRealReduceCap()}} <span style="color: #fff">kW</span></td>
            <td v-else>{{emsDetailRealReduceCap()}} kW</td>
            <td>總回饋金<span>(NTD)</span></td>
            <td><input type="num" class="w-l" v-model="cashBack"></td>
          </tr>
        </table>
      </div>
      <!-- 圖表 -->
      <div class="graphCheck-area">
        <div class="inner">
          <div class="circleGraph-area">
            <p class="title"><span>各單位貢獻度</span></p>
            <div class="circleGraph-img">
              <Highcharts :options="innerPieoptions" ref="highchart" />
            <!-- <img src="./img/circleGraph-img.png"> -->
            </div>
            <ul class="circleGraph-list">
              <li v-for="(i, idx) in emsDetail" :key="idx">
                <div class="color" :class="`bg-map${idx + 1}`"></div>
                  <p class="name">{{i.ems}}</p>
                  <p class="num color-map1" :class="`color-map${idx + 1}`"><span>{{i.contribution}}</span>%</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="btnbox" style="margin-top:15px">
        <a class="btn-check"  @click="postHistory" :style="{ background: buttonSpinnerColor }">儲存送出</a>
      </div>
    </div>
  </div>
  <div class="popup open" v-if="historyOpen">
    <div class="popup-area">
      <a class="popup-close" href="#" @click="historyOpen = false"></a>
      <h2 class="poptitle margin02">需量反應執行紀錄</h2>
      <div class="form-line">
        <div class="form-item">
          <span>得標價格</span>
          {{historyDetailtext.winBid}}
          <!-- <input type="number" class="w-m" v-model="winBid"> -->
          <span>元，</span>
        </div>
        <div class="form-item">
          <span>抑低契約容量</span>
          {{historyDetailtext.winReduceCap}}
          <!-- <input type="text" class="w-m" v-model="reduceCap"> -->
          <span>kW</span>
        </div>
      </div>
      <div class="form-line">
        <div class="form-item">
        <span>執行期間 {{historyDetailtext.execDate}} {{historyDetailtext.execTimesStart}} ~ {{historyDetailtext.execTimesEnd}} 共計2小時</span>
        </div>
      </div>
      <div class="form-line margin02">
        <div class="form-item">
          <span>實際總抑低容量</span>
          {{historyDetailtext.totalRealReduceCap}}
          <span>kW，</span>
        </div>
        <div class="form-item">
          <span>執行率 </span>
          <span class="font-size-xxl weight-n color-blue">{{dbDataHandle(historyDetailtext.execRate * 100, 0)}}</span>
          <span class="color-blue">%</span>
        </div>
      </div>
      <!-- table -->
      <div class="tableframe margin02">
        <table class="padding-small box-border">
          <tr class="tr-light border-bottom">
            <th>單位</th>
            <th>預估抑低容量(kW)</th>
            <th>實際抑低容量(kW)</th>
            <th>貢獻度(%)</th>
            <th>備註</th>
          </tr>
          <tr v-for="(i, idx) in historyDetailtext.emsDetail" :key="idx">
            <td>{{i.ems}}</td>
            <td>{{i.predictReduceCap}}</td>
            <td>{{i.realReduceCap}}</td>
            <td>{{i.contribution}}</td>
            <td>{{i.remark}}</td>
          </tr>
          <tr class="tr-dark font-size-l">
            <td>合計</td>
            <td>{{historyDetailtext.winReduceCap}} kW</td>
            <td>{{historyDetailtext.totalRealReduceCap}} kW</td>
            <td>總回饋金<span>(NTD)</span></td>
            <td>{{historyDetailtext.cashBack}}</td>
          </tr>
        </table>
      </div>
      <!-- 圖表 -->
      <div class="graphCheck-area">
        <div class="inner">
          <div class="circleGraph-area">
            <p class="title"><span>各單位貢獻度</span></p>
            <div class="circleGraph-img">
              <Highcharts :options="innerPieoptions" ref="highchart" />
            </div>
            <ul class="circleGraph-list">
              <li v-for="(i, idx) in historyDetailtext.emsDetail" :key="idx">
                <div class="color" :class="`bg-map${idx + 1}`"></div>
                  <p class="name">{{i.ems}}</p>
                  <p class="num color-map1" :class="`color-map${idx + 1}`"><span>{{i.contribution}}</span>%</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="wrapper">
    <!-- header -->
    <!-- 大網展開menu: header-menu-sec 移除hidden -->
    <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
    <header>
      <div class="pagesize">
        <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
          <p class="header-time">更新時間：{{updateTime}}</p>
          <a href="#" class="header-nav-btn"></a>
            <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
            </div>
        </div>
      </div>
    </header>
    <!-- container -->
    <div class="container">
      <div class="pagesize">
        <ul class="breadcrumbs">
          <li><a href="#">需量反應</a></li>
          <li><span>歷史紀錄</span></li>
        </ul>
        <div class="area bg-white margin02">
          <div class="padding03">
            <h2 class="boxtitle margin02">執行總覽</h2>
            <div class="history-area box-border box-round">
              <div class="flex-box flex-1 padding02">
                <ul class="numberList">
                  <li>
                    <div class="numberTag">
                      <p class="title">累計抑低容量</p>
                      <p class="tag"><span class="num">{{totalRealReduceCap}}</span>kW</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberTag">
                      <p class="title">累計回饋金額</p>
                      <p class="tag"><span class="num">{{totalCashBack}}</span>元</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberTag">
                      <p class="title">累計執行次數</p>
                      <p class="tag"><span class="num">{{totalexecTime}}</span>次</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberTag">
                      <p class="title">平均執行率</p>
                      <p class="tag"><span class="num">{{avgRate}}</span>%</p>
                    </div>
                  </li>
                  <li>
                    <div class="numberTag">
                      <p class="title">執行成功率</p>
                      <p class="tag"><span class="num">{{totalsuccess}} / {{totalexecTime}}</span>次</p>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="flex-box padding02 bg-gray-light">
                <div class="circleGraph-area">
                  <div class="circleGraph-img">
                  <p class="weight-bold margin02">平均貢獻度</p>
                    <!-- <img src="./img/circleGraph-img.png"> -->
                    <Highcharts :options="outOptions" ref="highchart" />
                  </div>
                  <ul class="circleGraph-list">
                    <li v-for="(i, idx) in outPieText" :key="idx">
                      <div class="color" :class="`bg-map${idx + 1}`"></div>
                        <p class="name">{{i[0]}}</p>
                        <p class="num color-map1" :class="`color-map${idx + 1}`"><span>{{i[1]}}</span>%</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="area bg-white">
          <div class="padding03">
            <h2 class="boxtitle f-left">得標紀錄</h2>
              <div class="btnbox f-right">
              <a href="#" class="btn btn-small bg-kahki" @click="openModal(true)">新增 +</a>
            </div>
            <div class="clr margin02"></div>
            <!-- table -->
            <div class="tableframe margin03">
              <table>
                <tr class="tr-dark">
                  <th v-for="(i, idx) in sortArray" :key="idx">
                    <button class="sortBtn" :class="{ down : !i.boolean,  up : i.boolean }" @click="sortReactHistory(i.dataText, idx)">{{i.text}}</button>
                  </th>
                  <th></th>
                </tr>
                <tr v-for="(i, idx) in historyPageFilter" :key="idx" @click="historyDetail(idx)" style="cursor: pointer">
                  <td>{{i.execDate}}</td>
                  <td>{{i.execResult === true ? '成功' : '失敗'}}</td>
                  <td>{{i.totalRealReduceCap}}</td>
                  <td>{{dbDataHandle(i.execRate * 100, 0)}}%</td>
                  <td >${{i.cashBack}}</td>
                  <td><a class="btn btn-small bg-kahki" @click.stop="deleteHistory(i.docId, i.pk)">刪除</a></td>
                </tr>
              </table>
            </div>
            <!-- pager -->
            <ul class="pager">
              <li v-for="i in reactHistoryPageLength" :key='i'><a :class="{on: pageCount === i}" @click="pageCount = i">{{i}}</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--  footer -->
    <footer>
      <div class="pagesize">
        <div class="footer-info">
          <div class="info-box info-main">
            <div class="logo"></div>
            <div class="copyright">
              <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
              <p>Copyright © All Rights Reserved</p>
            </div>
          </div>
          <div class="info-box info-sub">
            <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
            <div class="logo02"></div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
</template>

<style scoped>
    @import './css/inner.css';
</style>

<script>
import importJQ from './js/main'
import { mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import cookies from 'vue-cookies'
import axios from 'axios'
import { dbDataHandle } from '../store/function/index'
import Vue from 'vue'
export default {
  components: { DatePicker },
  data () {
    return {
      lastbid: {},
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      pageCount: 1,
      totalRealReduceCap: 0,
      totalCashBack: 0,
      avgRate: 0,
      totalsuccess: 0,
      totalexecTime: '',
      reactHistory: '',
      addReactHistory: '',
      predictReduce: '',
      cblSelectBeginHour: '01',
      cblSelectEndHour: Number(this.cblSelectBeginHour) + 2,
      cblSelectMin: '00',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      outPieText: [],
      modalOpen: false,
      winBid: 20,
      cashBack: 1000,
      reduceCap: 120,
      realReduceCap: 150,
      execResult: true,
      historyDetailtext: '',
      historyOpen: false,
      buttonSpinnerColor: '#8f7200',
      postLoading: false,
      sortArray: [
        {
          text: '執行時間',
          dataText: 'execDate',
          boolean: true
        },
        {
          text: '執行結果',
          dataText: 'execResult',
          boolean: true
        },
        {
          text: '實際抑低容量(kW)',
          dataText: 'totalRealReduceCap',
          boolean: true
        },
        {
          text: '執行率(%)',
          dataText: 'execRate',
          boolean: true
        },
        {
          text: '回饋金額(元)',
          dataText: 'cashBack',
          boolean: true
        }
      ],
      emsDetail: [
        {
          ems: '士林區公所',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        },
        {
          ems: '天文館',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        },
        {
          ems: '士林分局',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        },
        {
          ems: '士林運動中心',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        },
        {
          ems: '士東市場',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        },
        {
          ems: '士林市場',
          predictReduceCap: 0,
          realReduceCap: 0,
          remark: '',
          contribution: 0
        }
      ],
      outOptions: {
        chart: {
          backgroundColor: null,
          type: 'pie',
          height: 150,
          width: 250,
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          pie: {
            innerSize: 50,
            depth: 10,
            colors: ['#84763e', '#51ced9', '#ff850d', '#ffc321', '#8688db', '#89c011']
          }
        },
        series: [{
          name: '',
          data: [
            ['士林區行政中心', 3],
            ['天文科學教育館', 8],
            ['士林分局', 13],
            ['士林運動中心', 10],
            ['士林市場', 25],
            ['士東市場', 41]
          ]
        }]
      },
      innerPieoptions: {
        chart: {
          backgroundColor: null,
          type: 'pie',
          height: 150,
          width: 300,
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          pie: {
            innerSize: 50,
            depth: 10,
            colors: ['#84763e', '#51ced9', '#ff850d', '#ffc321', '#8688db', '#89c011']
          }
        },
        series: [{
          name: '',
          data: [
            ['士林區行政中心', 0],
            ['天文科學教育館', 0],
            ['士林分局', 0],
            ['士林運動中心', 0],
            ['士林市場', 0],
            ['士東市場', 0]
          ]
        }]
      }
    }
  },
  methods: {
    ...mapMutations([
      'COUNTING_TIMER',
      'LOGIN_OUT'
    ]),
    dbDataHandle (num, index) {
      return dbDataHandle(num, index)
    },
    async openModal (bool) {
      if (bool) {
        await this.getLastBid()
        this.winBid = this.lastbid.winBid ? this.lastbid.winBid : this.winBid
        this.reduceCap = this.lastbid.reduceCapacity ? this.lastbid.reduceCapacity : this.reduceCap
        this.execDate = this.lastbid.execDate ? this.lastbid.execDate : this.execDate
        this.cblSelectBeginHour = this.lastbid.execTime ? this.lastbid.execTime.slice(0, 2) : this.cblSelectBeginHour
        this.cblSelectMin = this.lastbid.execTime ? this.lastbid.execTime.slice(-2) : this.cblSelectMin
      }
      this.modalOpen = bool
    },
    emsDetailRealReduceCap () {
      const RealReduceCap = []
      this.emsDetail.forEach(x => RealReduceCap.push(x.realReduceCap))
      const total = RealReduceCap.reduce((a, b) => a + b)
      return total >= 0 ? total : 0
    },
    emsDetailcontribution () {
      if (this.realReduceCap <= 0) {
        for (let i = 0; i < this.emsDetail.length; i++) {
          this.emsDetail[i].contribution = 0
        }
        this.setInnerPieChart()
        return
      }
      let ReduceCap = 0
      for (let i = 0; i < this.emsDetail.length; i++) {
        if (this.emsDetail[i].realReduceCap >= 0) ReduceCap = ReduceCap + this.emsDetail[i].realReduceCap
      }
      for (let i = 0; i < this.emsDetail.length; i++) {
        this.emsDetail[i].contribution = this.dbDataHandle((this.emsDetail[i].realReduceCap / ReduceCap) * 100, 0) >= 0 ? this.dbDataHandle((this.emsDetail[i].realReduceCap / ReduceCap) * 100, 0) : 0
      }
      this.setInnerPieChart()
    },
    setInnerPieChart () {
      this.innerPieoptions.series[0].data = []
      for (let i = 0; i < this.emsDetail.length; i++) {
        const arr = [this.emsDetail[i].ems, this.emsDetail[i].contribution]
        this.innerPieoptions.series[0].data[i] = arr
      }
    },
    async predictReduceIndetail () {
      await this.getPredictReduce()
      for (let i = 0; i < this.emsDetail.length; i++) {
        this.emsDetail[i].predictReduceCap = this.predictReduce[i]
      }
    },
    async grandTotal () {
      await this.getReactHistory()
      this.totalRealReduceCap = 0
      this.totalCashBack = 0
      this.avgRate = 0
      this.totalsuccess = 0
      this.totalexecTime = this.reactHistory.data.length
      for (let i = 0; i < this.reactHistory.data.length; i++) {
        this.totalRealReduceCap = this.totalRealReduceCap + this.reactHistory.data[i].totalRealReduceCap
        this.totalCashBack = this.totalCashBack + this.reactHistory.data[i].cashBack
        this.avgRate = this.avgRate + this.reactHistory.data[i].execRate
        if (this.reactHistory.data[i].execResult) {
          this.totalsuccess = this.totalsuccess + 1
        }
      }
      this.avgRate = this.dbDataHandle((this.avgRate / this.totalexecTime) * 100)
      this.outPieChart()
    },
    outPieChart () {
      const arr = []
      for (let i = 0; i < this.reactHistory.data[0].emsDetail.length; i++) {
        arr[i] = []
        arr[i][1] = 0
      }
      let totalRealReduceCap = 0
      for (let i = 0; i < this.reactHistory.data.length; i++) {
        totalRealReduceCap = (this.reactHistory.data[i].totalRealReduceCap > 0 ? this.reactHistory.data[i].totalRealReduceCap : 0) + totalRealReduceCap
      }
      for (let i = 0; i < this.reactHistory.data.length; i++) {
        for (let j = 0; j < this.reactHistory.data[i].emsDetail.length; j++) {
          arr[j][0] = this.reactHistory.data[i].emsDetail[j].ems
          arr[j][1] = (this.reactHistory.data[i].emsDetail[j].realReduceCap > 0 ? this.reactHistory.data[i].emsDetail[j].realReduceCap : 0) + arr[j][1]
        }
      }
      for (let i = 0; i < arr.length; i++) {
        arr[i][1] = this.dbDataHandle((arr[i][1] / totalRealReduceCap) * 100, 0)
      }
      this.outPieText = arr
      this.outOptions.series[0].data = []
      for (let i = 0; i < arr.length; i++) {
        this.outOptions.series[0].data[i] = arr[i]
      }
    },
    sortReactHistory (str, idx) {
      this.historyPageFilter.set = this.reactHistory.data.sort((a, b) => {
        if (a[`${str}`] > b[`${str}`]) return 1
        if (a[`${str}`] < b[`${str}`]) return -1
        return 0
      })
      if (!this.sortArray[idx].boolean) {
        this.historyPageFilter.set = this.reactHistory.data.reverse()
      }
      this.sortArray[idx].boolean = !this.sortArray[idx].boolean
    },
    async postHistory () {
      if (!this.postLoading) {
        this.postLoading = true
        this.buttonSpinnerColor = 'gray'
        await this.theAddReactHistory()
        await this.grandTotal()
        this.openModal(false)
      }
    },
    async deleteHistory (docId, pk) {
      await this.thedeleteReactHistory(docId, pk)
      await this.grandTotal()
    },
    historyDetail (idx) {
      if (this.pageCount > 1) idx = idx + 10 * (this.pageCount - 1)
      this.historyDetailtext = this.reactHistory.data[idx]
      for (let i = 0; i < this.innerPieoptions.series[0].data.length; i++) {
        this.innerPieoptions.series[0].data[i][1] = this.historyDetailtext.emsDetail[i].contribution
      }
      this.historyOpen = true
    },
    async getLastBid () {
      const token = cookies.get('Authorization')
      const lastbid = await axios.post('/api/cems/getDefaultBid',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (lastbid.data.success) {
        this.lastbid = lastbid.data.body
      }
    },
    async getReactHistory () {
      const token = cookies.get('Authorization')
      const reactHistory = await axios.post('/api/cems/getReactHistory',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (reactHistory.data.success) {
        this.reactHistory = reactHistory.data
      }
      this.reactHistory.data = this.reactHistory.data.sort((a, b) => {
        if (a.execDate > b.execDate) return -1
        if (a.execDate < b.execDate) return 1
        return 0
      })
    },
    async getPredictReduce () {
      const token = cookies.get('Authorization')
      const predictReduce = await axios.post('/api/cems/getPredictReduce',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (predictReduce.data.success) {
        this.predictReduce = predictReduce.data.data
      }
    },
    async theAddReactHistory () {
      const token = cookies.get('Authorization')
      await axios.post('/api/cems/addReactHistory',
        {
          execDate: `reactHistory_${this.beginDate}`,
          winBid: Number(this.winBid),
          winReduceCap: Number(this.reduceCap),
          execResult: (Number(this.realReduceCap) / Number(this.reduceCap) >= 1) ? this.execResult : !this.execResult,
          execTimesStart: `${this.cblSelectBeginHour}:${this.cblSelectMin}`,
          execTimesEnd: `${('0' + (Number(this.cblSelectBeginHour) + 2)).slice(-2)}:${this.cblSelectMin}`,
          totalRealReduceCap: Number(this.realReduceCap),
          cashBack: Number(this.cashBack),
          saveTime: this.dbDataHandle(new Date().getTime() / 1000, 0),
          emsDetail: this.emsDetail
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      this.buttonSpinnerColor = '#8f7200'
      this.postLoading = false
      Vue.swal('儲存成功')
    },
    async thedeleteReactHistory (docId, pk) {
      const token = cookies.get('Authorization')
      await axios.delete('/api/cems/deleteReactHistory',
        {
          data: {
            docId,
            pk
          },
          headers: {
            Authorization: `bearer ${token}`
          }
        })
    }
  },
  mounted () {
    importJQ()
    this.predictReduceIndetail()
    this.grandTotal()
    this.setInnerPieChart()
  },
  computed: {
    reactHistoryPageLength () {
      if (this.reactHistory.data) {
        return Math.ceil(this.reactHistory.data.length / 10)
      } else {
        return 0
      }
    },
    historyPageFilter: {
      get () {
        if (this.reactHistory.data) {
          return this.reactHistory.data.filter((x, index) => {
            if (this.pageCount === 1) {
              return index < this.pageCount * 10
            } else {
              return index >= (this.pageCount - 1) * 10 && index < (this.pageCount - 1) * 10 + 10
            }
          })
        } else {
          return []
        }
      },
      set (newVal) {
        this.historyPageFilter = newVal
      }
    }
  }
}
</script>
